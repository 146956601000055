<template>
  <section id="hero">
    <v-carousel
      height="calc(90vh)"
      dark
      cycle
      show-arrows-on-hover
      draggable="true"
      hide-delimiter-background
      hide-delimiters
      show-arrows="hover"
    >
      <v-carousel-item
        :src="require(`@/assets/img/bellydancer_darken.jpg`)"
        width="100vw"
        cover
        class="gradient-fill justify-center stretch align-center EightyVh"
      >
        <v-container>
          <v-row justify="center" class="stretch pa-10 align-center EightyVh">
            <v-col class="justify-center">
              <h1
                class="text-md-h2 text-sm-h3 mt-7 text-h4 justify-center font-weight-black"
              >
                <div class="el-messiri">Experience the Mediterranean</div>
              </h1>

              <h3 class="mb-0">
                Share our family recipes with friends and meet new ones.
              </h3>
              <br />
              <!-- <v-btn
                href="https://www.ubereats.com/store/attiki-bar-and-grill/wboUSWA2RzuOGHKlRr96gA?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMjMzNiUyMFclMjBLZW5pbHdvcnRoJTIwU3QlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJiODQ5NzBlZC03ZDg2LTM5YjUtZGI2OC1hMDdmOWI3MzFkNzglMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIydWJlcl9wbGFjZXMlMjIlMkMlMjJsYXRpdHVkZSUyMiUzQTI5Ljk5Njc0NTIlMkMlMjJsb25naXR1ZGUlMjIlM0EtOTAuMTIwNjI0OSU3RA%3D%3D"
                size="x-large"
                class="my-3"
                color="error"
                target="_blank"
                >Get Started</v-btn
              > -->
            </v-col>
          </v-row>
        </v-container>
      </v-carousel-item>
      <v-carousel-item
        :src="require(`@/assets/img/food_darken.jpg`)"
        width="100vw"
        cover
        class="gradient-fill justify-center stretch align-center EightyVh"
      >
        <v-container>
          <v-row justify="center" class="stretch pa-10 align-center EightyVh">
            <v-col class="justify-center">
              <h1
                class="text-md-h2 text-sm-h3 mt-7 text-h4 justify-center font-weight-black"
              >
                <!-- <img
                  :src="require(`@/assets/img/ochsner-eat-fit.png`)"
                  style="width: 200px"
                /> -->
                <div class="el-messiri">Eat Fit NOLA</div>
              </h1>

              <h3 class="mb-0">
                Attiki Bar & Grill has partnered with Ochsner Health System to
                bring healthy menu selections
              </h3>
              <br />
              <!-- <v-btn
                size="x-large"
                class="my-3"
                :to="{ name: 'Menu' }"
                color="error"
                >See Menu</v-btn
              > -->
            </v-col>
          </v-row>
        </v-container>
      </v-carousel-item>
      <v-carousel-item
        :src="require(`@/assets/img/hookah_dark.jpg`)"
        width="100vw"
        cover
        class="gradient-fill justify-center stretch align-center EightyVh"
      >
        <v-container>
          <v-row justify="center" class="stretch pa-10 align-center EightyVh">
            <v-col class="justify-center">
              <h1
                class="text-md-h2 text-sm-h3 mt-7 text-h4 justify-center font-weight-black"
              >
                <div class="el-messiri">Oasis of The French Quarter</div>
              </h1>
              <h3 class="mb-0">
                Indulge in our Mediterranean delights, refreshing cocktails, and
                relaxing hookah, all in a 21 and up setting.
              </h3>
              <br />
              <!-- <v-btn size="x-large" class="my-3" color="error">See More</v-btn> -->
            </v-col>
          </v-row>
        </v-container>
      </v-carousel-item>
    </v-carousel>
  </section>
</template>

<script>
export default {
  data() {
    return {
      carouselsData: [
        {
          src: "@/assets/img/hookah.jpg",
          heading: " PROMOTE YOUR BUSINESS WITH US ",
          subHeading:
            "Infographic hypotheses influencer user experience Long madel ture gen-z paradigm shift client partner network product seilans solve management influencer analytics leverage virality. incubator seed round massmarket. buyer agile development growth hacking business-to-consumer ecosystem ",
        },
        {
          src: "@/assets/img/hookah.jpg",
          heading: " LOREM IPSUM DOLOR SIT AMET CONSEETU",
          subHeading:
            "Litora a interdum primis. Lectus facilisi sit cum arcu aliquam velit imperdiet sapien suspendisse at vel facilisis. Facilisis metus, curae; nibh mi orci et. Fringilla sociis magna rutrum bibendum arcu.",
        },
        {
          src: "@/assets/img/hookah.jpg",
          heading: " Build your website with this Theme ",
          subHeading:
            "Tellus cum turpis natoque id justo interdum tincidunt faucibus aptent congue pellentesque. Proin luctus orci netus! Scelerisque parturient cursus donec parturient et, luctus aptent habitant aliquet felis. Sapien placerat ultricies.",
        },
        {
          src: "@/assets/img/hookah.jpg",
          heading: " URNA TINCIDUNT MATTIS TORTOR ",
          subHeading:
            "Dapibus cras, ligula suspendisse potenti himenaeos mus volutpat pulvinar est facilisi. Vehicula malesuada parturient euismod litora mollis penatibus. Penatibus cum nullam facilisi enim nisi ac suspendisse. Ullamcorper ad amet netus.",
        },
      ],
    };
  },
};
</script>

<style>
.EightyVh {
  height: 90vh;
}
</style>
