<template>
  <div>
    <v-navigation-drawer v-model="drawer" fixed app temporary>
      <v-list dense>
        <v-list-item
          v-for="(item, i) in items"
          :key="i"
          color="primary"
          :to="item.to"
        >
          <v-list-item-title v-text="item.title.toUpperCase()" />
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      fixed
      app
      hide-on-scroll
      color="primary"
      height="64"
      elevate-on-scroll
    >
      <v-app-bar-nav-icon class="hidden-md-and-up" @click="drawer = true" />
      <a href="/" class="d-flex">
        <img
          :src="require(`@/assets/logo/attikilogo2.png`)"
          style="float: left; width: 150px; padding: 20px; margin-top: -5px"
        />
      </a>

      <div v-for="name in items" :key="name.title">
        <v-btn
          depressed
          tile
          plain
          class="py-8 hidden-sm-and-down"
          :to="name.to"
          >{{ name.title }}</v-btn
        >
      </div>
      <v-spacer />
      <a
        href="https://www.facebook.com/Attikineworleans"
        target="_blank"
        class="text-white no-underline"
      >
        <v-icon icon="mdi-facebook" class="pr-5"></v-icon>
      </a>
      <a
        href="https://www.instagram.com/attikineworleans/"
        target="_blank"
        class="text-white no-underline"
      >
        <v-icon icon="mdi-instagram"></v-icon>
      </a>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  name: "siteHeader",

  data() {
    return {
      clipped: false,
      drawer: false,
      items: [
        {
          title: "Menu",
          to: "/menu",
        },
        {
          title: "Hookah",
          to: "/hookah",
        },
        // {
        //   title: "Events",
        //   to: "/events",
        // },
        {
          title: "Gallery",
          to: "/gallery",
        },
        {
          title: "Press",
          to: "/press",
        },
        // {
        //   title: "Testimonials",
        //   to: "/testimonials",
        // },
        {
          title: "Contact",
          to: "/contact",
        },
        // {
        //   title: "Reservations",
        //   to: "/Reservations",
        // },
      ],
    };
  },
};
</script>

<style>
.full-width {
  width: 100%;
}
</style>
