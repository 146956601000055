// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";

// Vuetify
import { createVuetify } from "vuetify";

const myCustomLightTheme = {
  dark: true,
  colors: {
    background: "#390701",
    surface: "#7F3529",
    primary: "#510B01",
    "primary-darken-1": "#000000",
    secondary: "#510B01",
    "secondary-darken-1": "#ddb892",
    error: "#F5604A",
    info: "#000000",
    success: "#4CAF50",
    warning: "#f9c13b",
    "bg-dark-blue": "#001d3d",
    "bg-red": "#986b42",
    
  },
};

export default createVuetify({
  theme: {
    defaultTheme: "myCustomLightTheme",
    themes: {
      myCustomLightTheme,
    },
  },
});
