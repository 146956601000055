<template>
  <HeroSmall heading="Contact" imgPath="contact2.jpg" />

  <section>
    <v-row no-gutters>
      <v-col cols="12">
        <v-container>
          <v-row class="py-8">
            <v-col cols="12" md="6" class="text-center">
              <p class="pb-3">
                <v-icon icon="mdi-map-marker" class="pr-2"></v-icon>
                230 Decatur St., New Orleans, LA 70130
              </p>
              <p class="pb-3">
                <v-icon icon="mdi-phone" class="pr-2"></v-icon>
                (504) 587-3756
              </p>
              <p class="pb-3">
                <v-icon icon="mdi-email" class="pr-2"></v-icon>
                info@attikineworleans.com
              </p>
              <p>
                <a
                  href="https://www.facebook.com/Attikineworleans"
                  target="_blank"
                  class="text-white pa-3 no-underline"
                >
                  <v-icon icon="mdi-facebook" class="pr-2"></v-icon>
                </a>

                <a
                  href="https://www.instagram.com/attikineworleans/"
                  target="_blank"
                  class="text-white pa-3 no-underline"
                >
                  <v-icon icon="mdi-instagram" class="pr-2"></v-icon>
                </a>
              </p>
            </v-col>

            <v-col cols="12" md="6" class="text-center">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3456.9056817672404!2d-90.07020177404229!3d29.95339147199614!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8620a60d7a47be93%3A0xe80ff0a85337f2fa!2sAttiki%20Bar%20%26%20Grill!5e0!3m2!1sen!2sus!4v1653858991365!5m2!1sen!2sus"
                width="100%"
                height="500"
                frameborder="0"
                style="border: 0; display: block"
                allowfullscreen="true"
                aria-hidden="false"
                tabindex="0"
              ></iframe>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import HeroSmall from "@/components/HeroSmall.vue";

export default {
  name: "ContactView",
  components: {
    HeroSmall,
  },
  data: () => ({
    items: [
      { text: "230 Decatur St New Orleans, LA 70130", icon: " mdi-map-marker" },
      { text: "info@attikineworleans.com", icon: "mdi-email" },
      { text: "(504) 587-3756", icon: "mdi-phone" },
      { text: "7 days a week - Noon till 4am" },
    ],
  }),
};
</script>
