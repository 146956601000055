<template>
  <section>
    <v-img
      width="100%"
      height="17vh"
      :src="cover(imgPath)"
      :lazy-src="cover(imgPath)"
      class="gradient-fill"
      dark
      cover
    >
      <v-container fill-height>
        <v-row>
          <v-col>
            <h1 class="text-md-h2 text-sm-h3 text-h4 mt-7 text-center">
              {{ heading }}
            </h1>
          </v-col>
        </v-row>
      </v-container>
    </v-img>
  </section>
</template>

<script>
export default {
  props: {
    heading: {
      type: String,
      default: () => "",
    },
    imgPath: {
      type: String,
      default: () => "",
    },
  },
  methods: {
    cover(url) {
      if (url !== "") {
        // You can check any matching expression.
        try {
          url = require("@/assets/img/" + url);
        } catch (e) {
          console.log("catch", url);
          url = require("@/assets/img/bellydancer_darken.jpg"); // I used a default image.
        }
      } else url = require("@/assets/img/bellydancer_darken.jpg"); // Default image.
      return url;
    },
  },
};
</script>

<style scoped>
.gradient-fill .v-responsive__content {
  background: rgb(0, 0, 0);
  background: linear-gradient(
    to right,
    rgba(38, 68, 167, 0.7),
    rgba(5, 11, 31, 0.7)
  );
}

h1 {
  font-family: "El Messiri", sans-serif !important;
}
</style>
