<template>
  <div class="bg-background">
    <v-container class="text-center">
      <h1 class="pt-10">Follow us on Facebook</h1>
    </v-container>

    <v-responsive :aspect-ratio="30 / 9" style="overflow-y: hidden">
      <div class="powr-social-feed" id="12a3e67e_1655070362"></div>
    </v-responsive>
  </div>
</template>

<script>
export default {
  mounted() {
    //     var tagString = `<script type="text/javascript" src="https://www.powr.io/powr.js?platform=vuejs"/>`;

    // var range = document.createRange();
    // range.selectNode(document.getElementsByTagName("BODY")[0]);
    // var documentFragment = range.createContextualFragment(tagString);
    // document.body.appendChild(documentFragment);

    let externalScript = document.createElement("script");
    externalScript.setAttribute(
      "src",
      "https://www.powr.io/powr.js?platform=vuejs"
    );
    document.head.appendChild(externalScript);
  },
};
</script>

<style>
.powrMark,
.powrMark__logo-block {
  display: none !important;
}
</style>
