<template>
  <v-footer class="bg-primary mt-15">
    <v-container>
      <v-row>
        <v-col cols="12" sm="6" md="4" class="text-center">
          <h2 class="title text-uppercase mt-1 mb-4">About</h2>
          <p class="text-left">
            Attiki Bar and Grill is a family owned and operated authentic
            Mediterranean restaurant that opened a few months prior to Hurricane
            Katrina. Their dedication to New Orleans and its’ people made their
            return to the city inevitable. The owners, Jordanian natives, use
            family recipes handed down from generations in order to ensure that
            their guests experience genuine Mediterranean cuisine.
          </p>
          <v-row no-gutters>
            <v-col cols="12"> </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" sm="6" md="4" class="text-center">
          <div class="title text-uppercase mt-1 mb-4">
            <h2>Hours</h2>
          </div>

          <v-table dense style="background-color: transparent">
            <tbody>
              <tr>
                <td class="text-h7 text-uppercase mb-2">Mon-Thurs</td>
                <td class="text-h7 text-uppercase mb-2">11am - Midnight</td>
              </tr>
              <tr>
                <td class="text-h7 text-uppercase mb-2">Fri & Say</td>
                <td class="text-h7 text-uppercase mb-2">11am - 2am</td>
              </tr>
              <tr>
                <td class="text-h7 text-uppercase mb-2">Sun</td>
                <td class="text-h7 text-uppercase mb-2">closed</td>
              </tr>
              <!-- <tr>
                <td class="text-h7 text-uppercase  mb-2">Thursday</td>
                <td class="text-h7 text-uppercase mb-2">Noon - Midnight</td>
              </tr>
              <tr>
                <td class="text-h7 text-uppercase  mb-2">Friday</td>
                <td class="text-h7 text-uppercase mb-2">Noon - 1AM</td>
              </tr>
              <tr>
                <td class="text-h7 text-uppercase ">Saturday</td>
                <td class="text-h7 text-uppercase">Noon - 1AM</td>
              </tr>
              <tr>
                <td class="text-h7 text-uppercase  mb-2">Sunday</td>
                <td class="text-h7 text-uppercase mb-2">Noon - 1AM</td>
              </tr> -->
            </tbody>
          </v-table>
        </v-col>

        <v-col cols="12" sm="12" md="4" class="text-center">
          <div class="title text-uppercase mt-1 mb-4">
            <h2>Contact</h2>
          </div>

          <p class="pb-3">
            <v-icon icon="mdi-phone"></v-icon>
            (504) 587-3756
          </p>

          <p class="pb-3">
            <v-icon icon="mdi-email"></v-icon>
            info@attikineworleans.com
          </p>
          <p class="pb-3">
            <v-icon icon="mdi-map-marker" class="pr-2"></v-icon>
            230 Decatur St., New Orleans, LA 70130
          </p>

          <h1>
            <a
              href="https://www.facebook.com/Attikineworleans"
              target="_blank"
              class="text-white no-underline"
            >
              <v-icon icon="mdi-facebook" class="pr-2"></v-icon>
            </a>

            <a
              href="https://www.instagram.com/attikineworleans/"
              target="_blank"
              class="text-white no-underline"
            >
              <v-icon icon="mdi-instagram" class="pr-2"></v-icon>
            </a>
            <!-- <a
              href="https://twitter.com/attikineworlean"
              target="_blank"
              class="text-white no-underline"
            >
              <v-icon icon="mdi-twitter" class="pr-2"></v-icon>
            </a> -->
          </h1>
        </v-col></v-row
      >

      <v-divider class="mb-5 mt-5"></v-divider>
      <v-row>
        <v-col class="text--secondary text-center text-md-right">
          {{ new Date().getFullYear() }} © Attiki New Orleans
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped>
.bg-dark-blue {
  background-color: #001d3d !important;
  color: #ffffff !important;
}
</style>
