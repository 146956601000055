<template>
  <HeroSmall heading="Reservations" />

  <v-container>
    <iframe
      src="//www.yelpreservations.com/r/widget/?restaurant-id=25a3979b-f78f-4e4b-ac68-ae443c73a29c&orientation=vertical&color=500a00"
      width="100%"
      height="500"
      frameborder="0"
      style="border: 0; display: block"
      allowfullscreen="true"
      aria-hidden="false"
      tabindex="0"
    />
  </v-container>
</template>

<script>
import HeroSmall from "@/components/HeroSmall.vue";

export default {
  name: "ReservationsView",
  components: {
    HeroSmall,
  },
  data: () => ({}),
};
</script>

<style>
iframe,
object,
embed {
  max-width: 100%;
}
</style>
