<template>
  <HeroSmall heading="Testimonials" />
  <reviewsSlider />
</template>

<script>
import HeroSmall from "@/components/HeroSmall.vue";
import reviewsSlider from "@/components/reviewsSlider.vue";

export default {
  name: "MenuView",
  components: {
    HeroSmall,
    reviewsSlider,
  },
};
</script>
