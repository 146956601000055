<template>
  <v-row no-gutters>
    <v-col cols="12">
      <HeroSmall heading="Hookah" imgPath="hookah4.jpg" />
    </v-col>
  </v-row>

  <v-container>
    <v-container>
      <v-row no-gutters>
        <v-col cols="12" md="6" align-self="center">
          <v-img
            max-height="800"
            :src="require('@/assets/img/hookah6.jpg')"
            :lazy-src="require('@/assets/img/hookah6.jpg')"
          >
          </v-img>
        </v-col>
        <v-col cols="12" md="6" align-self="center">
          <div class="pa-lg-16 pa-md-10 px-4 py-16">
            <h1 class="text-center el-messiri font-weight-black">
              What's a Hookah?!
            </h1>
            <h3 class="text-h5 font-weight-thin text-left my-8">
              A hookah (hukkā or huqqah) also known as a waterpipe, arghile, or
              qalyān is a single or multi-stemmed instrument for smoking
              flavored tobacco called Mu‘assel in which the smoke is passed
              through a water basin (often glass based) before inhalation.
            </h3>
          </div>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12" md="6" align-self="center">
          <div class="pa-lg-16 pa-md-10 px-4 py-16">
            <h3 class="text-h5 font-weight-thin text-left my-8">
              The origin of the hookah is in India and Persia, or at a
              transition point between the two. The word hookah is a derivative
              of “huqqa”, which is what the Indians used to call it. According
              to author Cyril Elgood, it was Abul-Fath Gilani, a Persian
              physician at the Indian court of the Mughal emperor Akbar, who
              “first passed the smoke of tobacco through a small bowl of water
              to purify and cool the smoke and thus invented the hubble-bubble
              or hookah.”
            </h3>
          </div>
        </v-col>
        <v-col cols="12" md="6" align-self="center">
          <v-img
            max-height="800"
            :src="require('@/assets/img/hookah5.jpg')"
            :lazy-src="require('@/assets/img/hookah5.jpg')"
          >
          </v-img>
        </v-col>
      </v-row>

    </v-container>

    <v-row no-gutters>
      <v-col cols="12" md="6">
        <div class="pa-lg-16 pa-md-10 px-4 py-16">
          <h1 class="text-center el-messiri font-weight-black">
            Premium Flavors
          </h1>
          <h3 class="text-h5 font-weight-thin text-left my-8">
            Apple, Apricot, Banana, Blackberry, Cherry, Coconut, Grape, Guava,
            Kiwi, Lemon, Mango, Margarita, Mint, Orange, Peach, Pineapple,
            Redbull, Rose, Strawberry, Watermelon, Wildberry, Spider Monkey,
            Belly Dancer, Indian Candy, Pina Colada, Who Dat
          </h3>
        </div>
      </v-col>
      <v-col cols="12" md="6">
        <div class="pa-lg-16 pa-md-10 px-4 py-16">
          <h1 class="text-center el-messiri font-weight-black">
            Supplies & Accessories
          </h1>
          <h3 class="text-h5 font-weight-thin text-left my-8">
            Additional smokers are $5 each. All hookahs are limited to 4 people.
            <br /><br />

            Ask your server for current pricing on extra hookah, tobacco, hoses,
            bowls, and charcoal.
          </h3>
        </div>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col cols="12" md="6" align-self="center">
        <v-img
          max-height="800"
          :src="require('@/assets/img/hookah3.jpg')"
          :lazy-src="require('@/assets/img/hookah3.jpg')"
        >
        </v-img>
      </v-col>
      <v-col cols="12" md="6" align-self="center">
        <div class="pa-lg-16 pa-md-10 px-4 py-16">
          <h1 class="text-center el-messiri font-weight-black">Extras</h1>
          <h3 class="text-h5 font-weight-thin text-left my-8">
            Take your hookah to the next level by adding wine or liquor to the
            base.
            <br /><br />
            Milk, mint and ice are also options.
            <br /><br />
            An automatic gratuity of 18% may be added to any hookah purchase.
          </h3>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import HeroSmall from "@/components/HeroSmall.vue";

export default {
  name: "HookahView",
  components: {
    HeroSmall,
  },
  data: () => ({
  
  }),
};
</script>
