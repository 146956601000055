<template>
  <HeroCarousel />
  <FacebookFeed />
  <reviewsSlider />
</template>

<script>
import HeroCarousel from "@/components/HeroCarousel.vue";
import reviewsSlider from "@/components/reviewsSlider.vue";
import FacebookFeed from "../components/FacebookFeed.vue";

export default {
  name: "HomeView",
  components: {
    HeroCarousel,
    reviewsSlider,
    FacebookFeed,
  },
  data: () => ({}),
};
</script>

<style>
.js-powrMark {
  display: none;
}
</style>
