import { createWebHashHistory, createRouter } from "vue-router";
import Home from "@/views/HomeView.vue";
import ContactView from "@/views/ContactView.vue";
import EventsView from "@/views/EventsView.vue";
import GalleryView from "@/views/GalleryView.vue";
import HookahView from "@/views/HookahView.vue";
import MenuView from "@/views/MenuView.vue";
import PressView from "@/views/PressView.vue";
import ReservationsView from "@/views/ReservationsView.vue";
import TestimonialsView from "@/views/TestimonialsView.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/contact",
    name: "Contact",
    component: ContactView,
  },
  {
    path: "/events",
    name: "Events",
    component: EventsView,
  },
  {
    path: "/gallery",
    name: "Gallery",
    component: GalleryView,
  },
  {
    path: "/hookah",
    name: "Hookah",
    component: HookahView,
  },
  {
    path: "/menu",
    name: "Menu",
    component: MenuView,
  },
  {
    path: "/press",
    name: "Press",
    component: PressView,
  },
  {
    path: "/reservations",
    name: "Reservations",
    component: ReservationsView,
  },
  {
    path: "/testimonials",
    name: "Testimonials",
    component: TestimonialsView,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  // base: "/attiki2/",
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 };
  },
});

export default router;
