<template>
  <HeroSmall heading="Gallery" imgPath="glasses.jpg" />
  <gallerySection :images="galleryImages" />
</template>

<script>
import HeroSmall from "@/components/HeroSmall.vue";
import gallerySection from "@/components/gallerySection.vue";

export default {
  name: "GalleryView",
  components: {
    HeroSmall,
    gallerySection,
  },
  data: () => ({
    galleryImages: [
      { name: "Attiki-188" },
      { name: "Attiki-064" },
      { name: "Attiki-170" },
      { name: "Attiki-101" },
      { name: "Attiki-104" },
      { name: "Attiki-119" },
      { name: "Attiki-210" },
      { name: "Attiki-122" },
      { name: "Attiki-172" },
      { name: "Attiki-208" },
      { name: "Attiki-078" },
      { name: "Attiki-229" },
    ],
  }),
};
</script>
