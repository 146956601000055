<template>
  <v-app dark>
    <SiteHeader></SiteHeader>
    <v-main>
      <router-view />
    </v-main>
    <SiteFooter></SiteFooter>
  </v-app>
</template>

<script>
import SiteHeader from "./components/siteHeader.vue";
import SiteFooter from "./components/siteFooter.vue";

export default {
  name: "App",

  components: {
    SiteHeader,
    SiteFooter,
  },

  data: () => ({
    //
  }),
};
</script>

<style>
body {
  font-family: "Roboto";
}
h1,
h4,
h2,
h3,
.el-messiri,
.v-btn__content {
  font-family: "El Messiri", sans-serif !important;
}

.no-underline {
  text-decoration: none;
}
</style>
