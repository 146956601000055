<template>
  <HeroSmall
    :heading="heading"
    imgPath="attiki_new3.jpg"
  ></HeroSmall>
  <v-container>
    <v-tabs v-model="activeTypeTab" grow background-color="primary">
      <v-tab value="tab-food">Food</v-tab>
      <v-tab value="tab-spirits">Spirits</v-tab>
    </v-tabs>

    <v-card-text>
      <v-window v-model="activeTypeTab">
        <v-window-item value="tab-food">
          <v-tabs background-color="primary" show-arrows v-model="activeTab">
            <v-tab
              v-for="(tab, i) in tabsFood"
              :key="i"
              :value="'tab-' + tab"
              >{{ tab }}</v-tab
            >
          </v-tabs>

          <v-card-text>
            <v-window v-model="activeTab">
              <v-window-item value="tab-Appetizers">
                <menuItemTable :items="appetizers"></menuItemTable>
              </v-window-item>

              <v-window-item value="tab-Soups / Salads">
                <menuItemTable :items="soupsSalads"></menuItemTable>
              </v-window-item>

              <v-window-item value="tab-Entrees">
                <p class="pa-5 font-italic" style="background-color: #7f3529">
                  Include choice of Two Sides: Garlic Mashed Potatoes, Hummus,
                  Babaghanough, Cuscos, Rice with Pine Nuts, fresh Sauteed
                  Vegetables, or House Salad.
                </p>
                <menuItemTable :items="entrees"></menuItemTable>
              </v-window-item>
              <v-window-item value="tab-Pasta & Seafood">
                <menuItemTable :items="pastaSeafood"></menuItemTable>
              </v-window-item>
              <v-window-item value="tab-Wraps & Sandwiches">
                <menuItemTable :items="wrapsSandwiches"></menuItemTable>
              </v-window-item>
              <v-window-item value="tab-Lunch">
                <menuItemTable :items="lunch"></menuItemTable>
              </v-window-item>
              <v-window-item value="tab-Dessert">
                <menuItemTable :items="desserts"></menuItemTable>
              </v-window-item>
            </v-window>
          </v-card-text>
        </v-window-item>
        <v-window-item value="tab-spirits">
          <v-tabs
            background-color="primary"
            show-arrows
            v-model="activeSpiritsTab"
          >
            <v-tab
              v-for="(tab, i) in tabsSpirits"
              :key="i"
              :value="'tab-' + tab"
              >{{ tab }}</v-tab
            >
          </v-tabs>

          <v-card-text>
            <v-window v-model="activeSpiritsTab">
              <v-window-item value="tab-Draft Beer">
                <menuItemTable :items="draftBeers"></menuItemTable>
              </v-window-item>

              <v-window-item value="tab-Bottled Beer">
                <menuItemTable :items="bottledBeers"></menuItemTable>
              </v-window-item>

              <v-window-item value="tab-After Dinner">
                <menuItemTable :items="afterDinners"></menuItemTable>
              </v-window-item>
              <v-window-item value="tab-Cocktails">
                <menuItemTable :items="cocktails"></menuItemTable>
              </v-window-item>
              <v-window-item value="tab-Red Wine">
                <menuItemTable :items="redWines"></menuItemTable>
              </v-window-item>
              <v-window-item value="tab-White Wine">
                <menuItemTable :items="whiteWines"></menuItemTable>
              </v-window-item>
            </v-window>
          </v-card-text>
        </v-window-item>
      </v-window>
    </v-card-text>
  </v-container>
</template>

<script>
import HeroSmall from "@/components/HeroSmall.vue";
import menuItemTable from "@/components/menuItemTable.vue";

export default {
  name: "MenuView",
  components: {
    HeroSmall,
    menuItemTable,
  },
  data: () => ({
    heading: "Menu",
    activeTab: "tab-Appetizers",
    activeSpiritsTab: "tab-Draft Beer",
    activeTypeTab: "tab-food",
    tabsSpirits: [
      "Draft Beer",
      "Bottled Beer",
      "After Dinner",
      "Cocktails",
      "Red Wine",
      "White Wine",
    ],
    tabsFood: [
      "Appetizers",
      "Soups / Salads",
      "Entrees",
      "Pasta & Seafood",
      "Wraps & Sandwiches",
      "Lunch",
      "Dessert",
    ],
    draftBeers: [
      {
        name: "Kronenberg",
        description: "France",
      },
      {
        name: "New Castle",
        description: "England",
      },
      {
        name: "Hogarden",
        description: "Belgium",
      },
      {
        name: "Grimbergen",
        description: "Belgium",
      },
      {
        name: "Abita Amber",
        description: "USA",
      },
    ],
    bottledBeers: [
      {
        name: "Woodchuck",
        description: "USA",
      },
      {
        name: "Sierra Nevada",
        description: "USA",
      },
      {
        name: "Stella Artois",
        description: "Belgium",
      },
      {
        name: "Red Stripe",
        description: "Jamaica",
      },
      {
        name: "Psiner Urquel",
        description: "Czech",
      },
      {
        name: "Negra Modelo",
        description: "Mexico",
      },
      {
        name: "Miller Lite",
        description: "USA",
      },
      {
        name: "Michelob Ultra",
        description: "Belgium",
      },
      {
        name: "Lindemans Creek",
        description: "Belgium",
      },
      {
        name: "Lindemans Framboise",
        description: "Holland",
      },
      {
        name: "Heineken",
        description: "Holland",
      },
      {
        name: "Guiness",
        description: "Ireland",
      },
      {
        name: "Dos Equis",
        description: "Mexico",
      },
      {
        name: "Corona",
        description: "Mexico",
      },
      {
        name: "Coors Light",
        description: "USA",
      },
      {
        name: "Chimay Blonde",
        description: "Belgium",
      },
      {
        name: "Chimay Red",
        description: "Belgium",
      },
      {
        name: "Chimay Blue",
        description: "Belgium",
      },
      {
        name: "Bud Light",
        description: "USA",
      },
      {
        name: "Budweiser",
        description: "USA",
      },
      {
        name: "Blue Moon",
        description: "USA",
      },
      {
        name: "Becks",
        description: "USA",
      },
      {
        name: "Amstel",
        description: "Holland",
      },
      {
        name: "Almaza",
        description: "Lebanon",
      },
      {
        name: "Abita Purple Haze",
        description: "USA",
      },
      {
        name: "Abita Golden Wheat",
        description: "USA",
      },
    ],
    whiteWines: [
      {
        name: "Chardonnay",
        description: "Frei Brothers, Russian River",
      },
      {
        name: "Sauvignon Blanc",
        description: "Santa Carolina, Chile",
      },
      {
        name: "Vinho Verde",
        description: "Famega, Portugal",
      },
      {
        name: "Sauvignon Blanc",
        description: "Whitehaven, New Zealand",
      },
      {
        name: "Pinot Girigio",
        description: "Mezzacorona, Italy",
      },
      {
        name: "Pinot Grigio",
        description: "Arancio, Sicily",
      },
      {
        name: "Pinot Grigio",
        description: "Anterra, Italy",
      },
      {
        name: "Chardonnay",
        description: "William Hill, Central Coast",
      },
      {
        name: "Chardonnay",
        description: "Fat Bastard, France",
      },
      {
        name: "Riesling",
        description: "Vertikal, Germany",
      },
      {
        name: "Moscato",
        description: "Voga, Italy",
      },
      {
        name: "Moscato",
        description: "Mezzacorona, Italy",
      },
      {
        name: "White Zinfandel",
        description: "Beringer, California",
      },
      {
        name: "Sparkling",
        description: "Veuve Cliquot, France",
      },
      {
        name: "Sparkling",
        description: "Moet Imperial, France",
      },
      {
        name: "Sparkling",
        description: "Umberto Fiore Moscato D’Asti, Italy",
      },
      {
        name: "Sparkling",
        description: "Lamarca Prosecco",
      },
      {
        name: "Sparkling",
        description: "Segura Viudas, Spain",
      },
      {
        name: "Dessert Wine",
        description: "Chocolate Shop",
      },
    ],
    redWines: [
      {
        name: "Pinot Noir",
        description: "Bouchard, France",
      },
      {
        name: "Pinot Noir",
        description: "Tolloy, Italy",
      },
      {
        name: "Merlot",
        description: "Wente, San Francisco Bay",
      },
      {
        name: "Merlot",
        description: "Red Rock, Sonoma",
      },
      {
        name: "Merlot",
        description: "Copperidge, California",
      },
      {
        name: "Cabernet Sauvignon",
        description: "Alexander Valley, California",
      },
      {
        name: "Cabernet Sauvignon",
        description: "James Mitchel, Lodi",
      },
      {
        name: "Cabernet Sauvignon",
        description: "Deloach, Sonoma",
      },
      {
        name: "Red Zinfandel",
        description: "Lange Twins, Lodi",
      },
      {
        name: "Petit Syrah",
        description: "Petit Petit",
      },
      {
        name: "Malbec",
        description: "Alamos, Argentina",
      },
      {
        name: "Malbec",
        description: "Finca, Argentina",
      },
      {
        name: "Tempranillo",
        description: "Tapeña, Spain",
      },
      {
        name: "Chianti",
        description: "DaVinci, Italy",
      },
      {
        name: "Chianti",
        price: "Glass:8 Bottle:32",
      },
    ],
    cocktails: [
      {
        name: "Manhattan",
        description: "Maker’s Mark, sweet vermouth",
        price: "",
      },
      {
        name: "Attiki Bloody Mary",
        description:
          "Effen Cucumber Vodka and Attiki’s special blend of vegetables and juices made in house",
        price: "",
      },
      {
        name: "Sazerac",
        description: "Old Overholt & bitters, rimmed with Mati Hari Absinthe",
        price: "",
      },
      {
        name: "Old Fashioned",
        description:
          "Devil’s Cut Bourbon, bitters, and sour mix muddled with cherry and orange",
        price: "",
      },
      {
        name: "Cucumber Mojito",
        description:
          "Effen Cucumber Vodka muddled with fresh mint, lime, simple syrup, and splash of soda",
        price: "",
      },
      {
        name: "Italian Kiss",
        description:
          "Segura Viudas Brut, splash of Fragoli Strawberry Liqueur with wild strawberries",
        price: "",
      },
      {
        name: "Cuban Mojito",
        description:
          "Hendrick’s muddled with fresh mint, cucumber, simple syrup, and splash of soda",
        price: "",
      },
      {
        name: "Sun Kiss",
        description: "Stoli Ohranj, Grande Marnier",
        price: "",
      },
      {
        name: "Dreamsicle",
        description: "Stoli Ohranj, splash of Grande Marnier & cream",
        price: "",
      },
      {
        name: "Apple",
        description: "Ketel One, Dekuyper Apple Pucker, splash of Midori",
        price: "",
      },
      {
        name: "Mocha",
        description: "Tito’s, espresso, splash of Godiva & cream",
        price: "",
      },
      {
        name: "Respberry Truffle",
        description:
          "Stoli Razberi, splash of Chambord & Godiva, topped with cream",
        price: "",
      },
      {
        name: "Espresso",
        description: "Grey Goose, Kahlua, espresso",
        price: "",
      },
      {
        name: "Chocolate",
        description: "Tito’s Vodka, Godiva, splash of cream",
        price: "",
      },
      {
        name: "Lemon",
        description: "Stoli Citros, hint of vermouth",
        price: "",
      },
      {
        name: "Basil Gimlet",
        description:
          "Veev Acai Spirit shaken with fresh basil, lime juice, simple syrup, splash of soda",
        price: "",
      },
      {
        name: "Pomegranate",
        description: "Stoli Ohranj, Pama Liqueur, splash of cranberry",
        price: "",
      },
      {
        name: "Classic",
        description: "Tanqueray, hint of vermouth, olives",
        price: "",
      },
    ],
    afterDinners: [
      {
        name: "Lebanese Tea",
        description: "Tea mixture with rose water & pine nuts.",
      },
      {
        name: "Chai",
        description: "Choice with fresh mint.",
      },
      {
        name: "Turkish Coffee",
        description: "Served in small pot.",
      },
    ],
    pastaSeafood: [
      {
        name: "Redfish",
        description:
          "Sauteed Redfish topped with crawfish creamy white sauce served with fresh sauteed vegetables.",
        description2: "Served with House Salad.",
        description3: "Suggested Wine: Mezzacorona Pinot Grigio",
        price: "16.95",
      },
      {
        name: "Grilled Salmon",
        description:
          "Marinated in olive oil, lemon juice, & capers over a bed of greens served with fresh sauteed vegetables.",
        description2: "Served with House Salad.",
        description3: "Suggested Wine: Frei Brother’s Chardonnay",
        price: "18.95",
      },
      {
        name: "Seafood Cartoccio",
        description:
          "All fruit of the sea sautéed in garlic & herbs and lightly simmered in red wine & tomato reduction sauce over linguini.",
        description2: "Served with House Salad.",
        description3: "Suggested Wine: Tolloy Pinot Noir",
        price: "19.95",
      },
      {
        name: "Paella",
        description:
          "A traditional Spanish dish including shrimp, calamari, scallops, mussels, crawfish, sausage, & chicken simmered in Saffron broth topped over saffron rice with pine nuts.",
        description2: "Served with House Salad.",
        description3: "Suggested Wine: Vertikal Riesling",
        price: "22.95",
      },
      {
        name: "Chicken Artichoke Pasta",
        description:
          "Grilled chicken breast, artichokes, asparagus, mushroom, sun dried tomatoes served in a creamy white wine sauce over linguini.",
        description2: "Served with House Salad.",
        description3: "Suggested Wine: Famega Vinho Verde",
        price: "16.95",
      },
    ],
    desserts: [
      {
        name: "Tiramisu",
        price: "4.95",
      },
      {
        name: "Baklava",
        price: "4.95",
      },
      {
        name: "Cheesecake",
        price: "4.95",
      },
      {
        name: "Hot Fudge Chocolate Cheesecake",
        price: "4.95",
      },
      {
        name: "Pita Beignets",
        price: "4.95",
      },
      {
        name: "Fresh Fruit Dessert",
        price: "4.95",
      },
    ],
    lunch: [
      {
        name: "Soup & Half Sandwich",
        description:
          "Soup of the day served with choice of beef or chicken half wrap.",
        price: "9.95",
      },
      {
        name: "Soup & salad",
        description:
          "Soup of the day served with choice of House, Greek, or Caesar salad.",
        price: "9.95",
      },
      {
        name: "Grilled Salmon",
        description: "Served over greens. Served with a side salad.",
        price: "11.95",
      },
      {
        name: "Redfish",
        description:
          "Grilled lightly or fried topped with crawfish cream sauce. Served with a side salad.",
        price: "10.95",
      },
      {
        name: "Soft Shell Crab & Garlic Potatoes",
        description: "Served with a side salad.",
        price: "9.95",
      },
      {
        name: "Gyro, Hummus, & Tzatziki",
        description: "Served with a side salad.",
        price: "9.95",
      },
      {
        name: "Grape leaves, Kibby, & Hummus",
        description: "Served with a side salad.",
        price: "8.95",
      },
      {
        name: "Sauteed Vegetables, Hummus, & Rice",
        description: "Served with a side salad.",
        price: "8.95",
      },
      {
        name: "Falafel, Hummus, & Rice",
        description: "Served with a side salad.",
        price: "8.95",
      },
      {
        name: "Hummus, Babaganough, & Tabullah",
        description: "Served with a side salad.",
        price: "8.95",
      },
      {
        name: "Chicken Kabob Tips or Shawarma",
        description: "Served with hummus & rice. Served with a side salad.",
        price: "8.95",
      },
      {
        name: "Beef Kabob Tips or Shawarma",
        description: "Served with hummus & rice. Served with a side salad.",
        price: "9.95",
      },
    ],
    wrapsSandwiches: [
      {
        name: "Attiki Burger",
        description:
          "100% ground meat grilled to your liking. Served with fries.",
        price: "8.95",
      },
      {
        name: "Soft Shell Crab Wrap",
        description:
          "Crispy fried soft shell crab wrapped in pita bread. Served with fries.",
        price: "8.95",
      },
      {
        name: "Veggie Sandwich Wrap",
        description:
          "Sauteed asparagus, mushrooms, sun dried tomatoes, red onions, bell pepper, topped with salad & feta",
        price: "7.95",
      },
      {
        name: "Gyro Wrap",
        description:
          "Spring lamb thinly sliced stuffed in pita bread with tzatziki, lettuce, tomatoes, & cucumber. Served with fries.",
        price: "7.95",
      },
      {
        name: "Shish Shawarma Wrap",
        description:
          "Shish wrapped in pita bread topped with salad and sesame sauce & served with fries.",
        price: "7.95",
      },
      {
        name: "Shrimp Shawarma Wrap",
        description:
          "Shrimp wrapped in pita bread topped with salad and sesame sauce & served with fries.",
        price: "9.95",
      },
      {
        name: "Chicken Shawarma Wrap",
        description:
          "Sliced chicken wrapped in pita bread topped with salad and sesame sauce & served with fries.",
        price: "6.95",
      },
      {
        name: "Beef Shawarma Wrap",
        description:
          "Sliced beef wrapped in pita bread topped with salad and sesame sauce & served with fries.",
        price: "7.95",
      },
      {
        name: "Shrimp Kabob Wrap",
        description:
          "Shrimp wrapped in pita bread stuffed with hummus, salad, & served with fries.",
        price: "9.95",
      },
      {
        name: "Chicken Kabob Wrap",
        description:
          "Grilled chicken breast cube cut wrapped in pita bread stuffed with hummus, salad, & served with fries.",
        price: "7.95",
      },
      {
        name: "Beef Kabob Wrap",
        description:
          "Grilled cubed cut US Prime choice wrapped in pita bread stuffed with hummus, salad, & served with fries.",
        price: "8.95",
      },
      {
        name: "Soft Shell Crab",
        description:
          "Crispy soft shell crab lightly fried with our specialty seasoning topped with crawfish cream sauce served with mashed potatoes.",
        price: "17.95",
      },
      {
        name: "Shrimp Primavera",
        description:
          "Jumbo shrimp simmered in light broth with asparagus, mushrooms & sun dried tomatoes over linguini.",
        price: "18.95",
      },
    ],
    entrees: [
      {
        name: "Rack of Lamb",
        description:
          "A house specialty spring hand cut rack of lamb marinated in our specialty garlic seasoning.",
        price: "22.95",
      },
      {
        name: "Filet Mignon",
        description:
          "8 oz US #1 choice, grilled & topped with our creamy mushroom wine sauce.",
        price: "23.95",
      },
      {
        name: "Combinations Platter",
        description: "Prime steak tips, chicken & shish",
        price: "19.95",
      },
      {
        name: "Shish Kabob",
        description:
          "Ground prime meat rolls blended with savory herbs & olive oil.",
        price: "15.95",
      },
      {
        name: "Shrimp Kabob",
        description:
          "Fresh grilled jumbo shrimp. Marinated at least 24 hours in our special herbs & olive oil.",
        price: "18.95",
      },
      {
        name: "Chicken Shawarma",
        description:
          "Thinly sliced chicken. Marinated at least 24 hours in our special herbs & olive oil.",
        price: "15.95",
      },
      {
        name: "Chicken Kabob",
        description:
          "Grilled cubed cut. Marinated at least 24 hours in our special herbs & olive oil.",
        price: "15.95",
      },
      {
        name: "Beef Shawarma",
        description:
          "Thinly sliced US Prime cut. Marinated at least 24 hours in our special herbs & olive oil.",
        price: "17.95",
      },
      {
        name: "Beef Kabobs",
        description:
          "Grilled cubed cut US Prime choice. Marinated at least 24 hours in our special herbs & olive oil.",
        price: "17.95",
      },
    ],
    soupsSalads: [
      {
        name: "House Salad",
        description:
          "Add Chicken 3.95 Add Beef 4.95 Add Shrimp, Scallops, or Squid 5.95 Add Vegetables 3.95",
        price: "6.95",
      },
      {
        name: "Seared Tuna Salad",
        description:
          "Seared tuna over a bed of spring mix Suggested Wine: Mezzacorona Moscato",
        price: "8.95",
      },
      {
        name: "Classic Caesar",
        description:
          "Romaine & pita croutons mixed in our Caesar dressing topped with fresh feta and parmesan.",
        price: "7.95",
      },
      {
        name: "Greek Salad",
        description:
          "House specialty Suggested Wine: Famega Vinho Verde Classic",
        price: "8.95",
      },
      {
        name: "Tomato Mozzerella",
        description:
          "Fresh imported mozzarella ball, tomatoes, red onions, sun dried tomatoes, kalamata olives.",
        price: "8.95",
      },
      {
        name: "Lentil Soup Bread Bowl",
        description: "",
        price: "6.95",
      },

      {
        name: "Lentil Soup Cup",
        description: "",
        price: "2.99",
      },
    ],
    appetizers: [
      {
        name: "Babaganoush",
        description:
          "Roasted eggplant smoothly blended with tahini, olive oil & lemon juice.",
        price: "4.95",
      },
      {
        name: "Tabullah",
        description:
          "Finely chopped tomatoes, parsley, onions, cracked wheat, olive oil, lemon juice.",
        price: "4.95",
      },
      {
        name: "Falafel (6)",
        description: "Ground chickpeas, special herbs & fried to perfection.",
        price: "6.95",
      },
      {
        name: "Kibby (1)",
        description:
          "Cracked wheat crust stuffed with ground beef, onions & pine nuts",
        price: "6.95",
      },
      {
        name: "Fried Calamari",
        description: "A local favorite",
        price: "8.95",
      },
      {
        name: "Grape Leave Rolls (5)",
        description:
          "Fresh grape leaves, stuffed with rice, ground beef & herbs.",
        price: "6.95",
      },
      {
        name: "Vegetarian Grape Leave Rolls (5)",
        description: "Fresh grape leaves stuffed with rice & herbs.",
        price: "6.95",
      },
      {
        name: "Tomato Buffalo ",
        description:
          "Baked sliced tomatoes topped with imported mozzarella, basil & extra virgin olive oil.",
        price: "7.95",
      },
      {
        name: "Hummus",
        description:
          "House made specialty of smoothly blended chick peas, tahini, lemon & olive oil.",
        price: "4.95",
      },
      {
        name: "Lebneh",
        description: "Smooth yogurt made in house and topped with olive oil.",
        price: "4.95",
      },
      {
        name: "Mazze",
        description:
          "Babaghanough, Hummus, Kibby, Lebneh, Buffalo, Grape Leaves, Falafel.",
        price: "19.95",
      },
      {
        name: "Foul Madammas",
        description:
          "Mixture of fava beans, topped with chopped tomatoes & parsley.",
        price: "6.95",
      },
      {
        name: "Musabaha",
        description: "Hummus topped with fava beans.",
        price: "6.95",
      },
      {
        name: "Fetta",
        description: "Hummus topped with pine nuts & small pieces of steak.",
        price: "6.95",
      },
      {
        name: "Haloumi",
        description:
          "A traditional Cyprus cheese lightly grilled with olive oil & lemon juice.",
        price: "6.95",
      },
      {
        name: "Cheese Platter",
        description: "Assorted cheese served with pita chips.",
        price: "9.95",
      },
      {
        name: "Pita Pizza/Fatayer",
        description: "Fresh mozzarella, tomatoes, and basil.",
        price: "8.95",
      },
      {
        name: "Attiki Steak Fries",
        description:
          "Fries topped with beef steak or chicken, meuniere sauce & feta",
        price: "7.95",
      },
    ],
  }),
};
</script>
