<template>
  <div>
    <v-container class="text-center">
      <h1 class="pt-10">Customer Reviews</h1>
      <section id="testimonials">
        <v-carousel
          light
          cycle
          draggable="true"
          hide-delimiter-background
          hide-delimiters
          show-arrows="hover"
        >
          <v-carousel-item v-for="(item, i) in testimonials" :key="i">
            <v-container fill-height fluid>
              <v-row align="center" justify="center">
                <v-col>
                  <div class="align-center justify-center">
                    <h3 class="mb-1 font-weight-black text-uppercase">
                      {{ item.name }}
                    </h3>
                    <div
                      v-if="item.post"
                      class="mb-10 font-weight-light grey--text text-uppercase"
                    >
                      {{ item.post }}
                    </div>
                    <div v-else><br /><br /></div>

                    <v-row>
                      <v-col cols="12" md="6" offset-md="3">
                        <div class="mb-5 font-italic text-left">
                          <v-icon x-large class="grey--text text--lighten-1"
                            >mdi-format-quote-open</v-icon
                          >
                          {{ item.quote }}&nbsp;<v-icon
                            x-large
                            class="grey--text text--lighten-1"
                            >mdi-format-quote-close</v-icon
                          >
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-carousel-item>
        </v-carousel>
      </section>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      testimonials: [
        {
          name: "Sylvester J.",
          post: "Woodbridge, VA",
          quote:
            "Just at Jazz Festival and was looking for some place to get lunch. We fell upon this place and it was the best meal we had. The ingredients were fresh, right portion and priced right. Then we had great drinks from the bartender 'Jasmine?'.  The wait staff was also great in there professionalism and attentiveness to our needs. Such GREAT food and service we took the group of 6 back the next day for dinner.",
        },
        {
          name: "Ra C.",
          post: "Lake Hiawatha, Lake Hiawatha, NJ",
          quote:
            "Great place for food, drinks and hookah. Came from Jersey and really enjoyed myself here. Great hospitality! Jasmine is awesome!",
        },
        {
          name: "Mark S. Williams",
          quote:
            "The food was fantastic! I had the combination platter of kebabs. This is some of the best food I have had in New Orleans. Service was friendly and the food was prompt. Live entertainment later tonight.",
        },
        {
          name: "Gerry G.",
          post: "San Bernadino, CA",
          quote:
            "Traveling in from California, and wanted to check this place out from doing research. The young gentleman working named Bank is awesome and very polite and has great customer service. Happy hour is from 3pm- 6? Cheap drinks and good hooka options.",
        },
        {
          name: "Luis G.",
          post: "San Francisco, CA",
          quote:
            "La comida y atención fueron excelentes, es bueno y reconfortante encontrar un lugar con comida diferente en medio de tantos lugares que ofrece el mismo tipo de menú",
        },
        {
          name: "Corey N.",
          post: "Marrero, LA",
          quote:
            "Faris is the best, wonderful service 10/10 for any night out in the city! Bring your friends, family, and strangers. You'll have a wonderful time with or without them!",
        },
        {
          name: "Melissa",
          quote:
            "I really enjoy myself everytime I go here. The service is so professional, the atmosphere is unique & comforting, and the vibe is just a special feeling of relaxation. Being able to smoke a hookah at a table seated next to an open window leading to a French Quarter street over a glass of wine and a full belly -breath- is an elegant experience. A must. I actually became a regular because of this.",
        },

        {
          name: "Ruth B.",
          post: "Denver, CO",
          quote:
            "Fantastic Mediterranean food, very refreshing in the heat. Hookahs are smooth and long-lasting and there's not any feeling of pressure to get in and get out.  Def check this place out if you want some hookah, good food, and champagne!",
        },

        {
          name: "Addonis C.",
          post: "Houma, LA",
          quote:
            "LAAAAAWD this place is so good! I wanted to order everything on that menu! The drinks were fantastic and our waiter (young man, maybe 18) was exceptional! No hookahs this time, but this food was to die for and is a great late night bite. Will be back!",
        },

        {
          name: "Tatyana S.",
          post: "Virginia Beach, VA",
          quote:
            "Hookah is unmatched!!!! By far some of the best I've had. Not sure what other reviews are referring to. Our hookah lasted over 2 hours. Flavor never went away! And the drinkkksssss are strongggggg! Highly recommend!!",
        },
      ],
    };
  },
};
</script>
