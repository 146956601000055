<template>
  <v-table class="pt-2">
    <tbody>
      <tr v-for="item in items" :key="item.name">
        <td>
          <h2 class="el-messiri text-left">
            {{ item.name }}
          </h2>
          <div class="text-h6 font-weight-thin text-left mb-2 ml-2">
            {{ item.description }}
          </div>
          <div class="text-h6 font-weight-thin text-left mb-2 ml-2">
            {{ item.description2 }}
          </div>
          <div class="text-h6 font-weight-thin text-left mb-2 ml-2">
            {{ item.description3 }}
          </div>
        </td>
        <td class="text-h6 font-weight-thin text-right mb-2">
          {{ item.price }}
        </td>
      </tr>
    </tbody>
  </v-table>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
