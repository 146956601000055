<template>
  <v-row no-gutters>
    <v-col cols="12">
      <HeroSmall heading="Press" imgPath="press.jpg" />
    </v-col>
  </v-row>

  <v-container>
    <h2>Attiki Bar & Grill</h2>

    <p class="font-italic">by Lora Ghawaly for Gambit</p>
    <br />
    <p>
      Hookah smoke, belly dancers and fresh hummus sound like the makings of a
      good night in a distant Middle Eastern country, but New Orleanians can
      step right into that culture in a Decatur Street restaurant.
    </p>
    <br />
    <p>
      “The belly dancing is definitely a big draw,” says Diana Canahuati, owner
      of Attiki Bar & Grill (230 Decatur St., 587-3756;
      www.attikineworleans.com). “Regulars know what to expect, but newcomers
      are amazed by the traditional dancing. It keeps it interesting.”
    </p>
    <br />
    <p>
      Hookah smoking also was a big draw until the smoking ban forced Canahuati
      to curtail it until she can get a license. “I’ve been doing hookah since
      before the smoking ban,” she says. “[Getting the hookah license] will take
      time, but it’s worth it. Hookah is one of my signatures at Attiki.”
    </p>
    <br />
    <p>
      Attiki features culinary specialties of the Middle East and the
      Mediterranean. Italian, Spanish and Greek cuisines star as some of the
      menu’s major influences. Popular items include paella, kebabs, hummus and
      rack of lamb.
    </p>
    <br />
    <hr />
    <br />

    <h2>Experience the Mediterranean at Attiki Bar & Grill</h2>
    <p class="font-italic">InTheNola.com</p>

    <p class="mt-5">
      New Orleanians know they love their fried seafood, hot sausage po-boys,
      and daiquiris. And lately, one can enjoy Honduran yucca and Vietnamese
      pho, and Thai pad thai and Indian chicken biryani. Yes, the New Orleans
      food scene has spread its wings a little bit, inviting some diverse dining
      options.
    </p>
    <br />
    <p>
      Attiki Bar and Grill just happens to be one of those diverse dining
      options. Tall, fully open doors and music from somewhere far far away. A
      Saint’s jersey and game schedule are a reminder that we’re IntheNOLA but
      the foreign draft beer, old juke box and cigarette machine, comfy couch,
      and antique lamps say otherwise. A lounge for smoking hookah, a bar for
      eating, drinking, and socializing, as well as inside and outside dining
      tables for the restaurant side. Attiki Bar and Grill offers it all.
    </p>
    <br />
    <p>
      A Mediterranean restaurant and bar, Attiki Bar and Grill offers the whole
      package according to owner Diana Canahuati. “Attiki is more like a
      lounge,” she said. “We offer Mediterranean food, beer and wine from all
      over the world, as well as martinis, hookah, and belly dancing on the
      weekends. It’s a brand new relationship with the locals in the city as
      well as the tourists that have been coming for years.”
    </p>
    <br />
    <p>
      Located in the French Quarter, Attiki’s menu ranges from rack of lamb and
      paella to kebabs and calamari. In addition, beers, including a must try
      German draft (Kronenbourg), liquors, and a wine list that expands to
      countless other regions, are featured at Attiki. “We also have a rare
      liquor from Lebanon that’s become a local favorite.”
    </p>
    <br />
    <p>
      Attiki aims to make you feel comfortable and relaxed with its low-key
      atmosphere. “I was brought up to be very hospitable and my staff and I
      make sure that everyone is taken care of. I want our customers to feel as
      if they are almost at a friend’s house or a place to relax, having a good
      time. Here, there’s no reason to get scared if you don’t know a food or
      wine. Just ask and we’re glad to explain to you, we’re glad to let you
      sample the wine.”
    </p>
    <br />
    <p>
      In addition to the food and drinks, customers can also indulge in a little
      hookah and listen to a diverse selection of world music, “producing all
      kinds of different vibes,” explained Diana. “We have Mediterranean music
      as well as music from Spain, India, and even classic American. After
      dinner time, because our kitchen is open until one and we don’t close
      until four, the atmosphere changes up. The music plays louder and people
      come to unwind.”
    </p>
    <br />
    <p>
      Attiki Bar and Grill always has events going on, such as birthdays,
      graduation and bachelorette parties, and other gatherings. “We also have
      belly dancing every Friday and Saturday. It was really big before Katrina
      and now the belly dancers we have are wonderful and have been with me
      through the years. They got to know all the locals and regulars, and have
      become good friends with them. Not only do our belly dancers know how to
      dance, but they also have great personalities, and they show how belly
      dancing is a beautiful thing and an art to respect.”
    </p>
    <br />
    <p>
      Attiki Bar and Grill is open Monday through Sunday for lunch, dinner, and
      even later dinner! “Everything we do here is made to order. Basically,
      just come and enjoy yourself, and feel like you’re at home. Have a good
      time because we have a great menu!”
    </p>

    <br />
    <hr />
    <br />

    <h2>Attiki Bar & Grill</h2>
    <p class="font-italic">by Aariel Charbonnet for Gambit</p>
    <br />
    <p>
      Diana Canahuati, chef/owner of Attiki Bar & Grill (230 Decatur St.,
      587-3756;www.attikineworleans.com), always dreamed of having her own
      restaurant. Just two months after opening the French Quarter spot,
      however, her dream was threatened when Hurricane Katrina made landfall and
      levee failures filled much of the city with water.
    </p>
    <br />
    <p>
      “The whole ball changed,” Canahuati says. “The challenges changed. No
      tourism. We didn’t know how long it would take to get the city back. We
      had to do whatever we could to get it done.”
    </p>
    <br />
    <p>
      She and her staff did just that, offering a vast selection of wine and
      spirits and signature Mediterranean dishes served in an exotic atmosphere.
      The combination has garnered the restaurant a loyal daytime following and
      late-night revelers aplenty.
    </p>
    <br />
    <p>
      Appetizers include grape leaves, hummus, fried calamari and tomato bufala
      (mozzarella cheese made with buffalo milk). For lunch, Canahuati
      recommends Attiki’s signature chicken, beef, shrimp or shish kebabs.
      Dinner specialties range from rack of lamb marinated in Attiki’s signature
      garlic seasoning to paella, a traditional Spanish dish loaded with
      seafood, sausage and chicken served over rice pilaf. Baklava and tiramisu
      are among the authentic Mediterranean desserts. Patrons also can puff on
      hookah bowls of flavored tobacco.
    </p>
    <br />
    <p>
      In addition to lunch, dinner and late-night menus, Attiki hosts belly
      dancing performances on Saturday nights, accompanied by a medley of
      international music.
    </p>
    <br />
    <p>
      “Attiki is a place you come that’s totally different,” Canahuati says.
      “It’s getting away from the everyday.”
    </p>
    <br />
    <p>
      Canahuati’s parents immigrated to the United States from Jordan when she
      was 9 years old. She grew up in New Orleans and developed a strong
      attachment to the city. In 2005, she opened Attiki in the French Quarter.
      She chose the name (Attiki means “to give” in Arabic) after honeymooning
      in Greece, which has a region called Attiki.
    </p>
    <br />
    <p>
      “Attiki offers traditional music, a relaxed atmosphere,” says Canahuati,
      who hopes to open a second location some day. “It’s different from the
      regular bar. You also get to drink something different from other places.
      Some (bars) don’t offer the variety of international alcohol we do. We
      just give the whole package.”
    </p>
  </v-container>
</template>

<script>
import HeroSmall from "@/components/HeroSmall.vue";

export default {
  name: "PressView",
  components: {
    HeroSmall,
  },
  data: () => ({}),
};
</script>
