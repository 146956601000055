<template>
  <v-row no-gutters>
    <v-col cols="12">
      <HeroSmall heading="Events & Catering" imgPath="catering4.jpg" />
    </v-col>
  </v-row>

  <v-container>
    <v-row no-gutters>
      <v-col cols="12" md="6" align-self="center">
        <v-img
          max-height="800"
          :src="require('@/assets/img/bottles.jpg')"
          :lazy-src="require('@/assets/img/bottles.jpg')"
        >
        </v-img>
      </v-col>
      <v-col cols="12" md="6" align-self="center">
        <div class="pa-lg-16 pa-md-10 px-4 py-16">
          <h1 class="text-center el-messiri font-weight-black">Happy Hour</h1>

          <h3 class="text-h5 text-uppercase font-weight-thin text-center my-8">
            3pm - 6pm Mon-Thur
           
          </h3>
          <menuItemTable :items="happyHourItems" />
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" md="6" align-self="center">
        <div class="pa-lg-16 pa-md-10 px-4 py-16">
          <h1 class="text-center el-messiri font-weight-black">
            Belly Dancers
          </h1>
          <h3 class="text-h5 text-uppercase font-weight-thin text-center my-8">
            Come join us every Friday and Saturday night at Attiki Bar and Grill
            at 10:30p.
          </h3>

          <h1 class="text-center el-messiri font-weight-black">S.I.N.</h1>
          <h3 class="text-h5 text-uppercase font-weight-thin text-center my-8">
            Tuesday and Thursday Happy Hour prices all day long for service
            industry workers.
          </h3>
        </div>
      </v-col>
      <v-col cols="12" md="6" align-self="center">
        <v-img
          max-height="800"
          :src="require('@/assets/img/homebackground2.jpg')"
          :lazy-src="require('@/assets/img/homebackground2.jpg')"
        >
        </v-img>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col cols="12" align-self="center">
        <div class="pa-lg-16 pa-md-10 px-4 py-16">
          <h1 class="text-left el-messiri font-weight-black">Catering Menu</h1>
          <h3 class="text-h5 text-uppercase font-weight-thin text-left my-8">
            Whether you are planning an intimate dinner, corporate lunch, or a
            social event for a special occasion, our staff will work with you to
            make sure your experience is a memorable one. We offer on-site
            private dining for those desiring a French Quarter setting, as well
            as off-site catering for events in the New Orleans area. Allow your
            guests to experience the Mediterranean with Attiki’s food and hookah
            catering services.
          </h3>

          <h3 class="text-h5 text-uppercase font-weight-thin text-center my-8">
            Call 504-587-3756 for information and event pricing.
          </h3>
        </div>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col cols="12">
        <menuItemTable :items="cateringItems" />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import HeroSmall from "@/components/HeroSmall.vue";
import MenuItemTable from "@/components/menuItemTable.vue";

export default {
  name: "EventsView",
  components: {
    HeroSmall,
    MenuItemTable,
  },
  data: () => ({
    happyHourItems: [
      { name: "Domestic Beers", price: "$2" },
      { name: "Abita and New Castle Draft", price: "$3" },
      { name: "Well Liquors", price: "$3" },
      { name: "Hookahs", price: "$15" },
    ],
    cateringItems: [
      {
        name: "Baklava 30/pcs",
        description: "Freshly made with filo & walnut mixture.",
      },
      {
        name: "Fruit Tray Display",
        description: "",
      },
      {
        name: "Greek Salad Tray",
        description: "",
      },
      {
        name: "Paella for 10 people",
        description:
          "Shrimp, calamari, scallops, mussels, crawfish, sausage & chicken simmered in saffron broth",
      },
      {
        name: "Vegetarian Pasta Tray",
        description:
          "Artichokes, asparagus, mushrooms & sun-dried tomatoes sautéed in a creamy white wine sauce served over linguini pasta.",
      },
      {
        name: "Chicken Artichoke Pasta Tray",
        description:
          "Grilled chicken breast, artichokes, asparagus, mushrooms, sun-dried tomatoes sautéed in a creamy white wine sauce served over linguini pasta.",
      },
      {
        name: "Mediterranean Sandwich Rolls 50 pcs",
        description:
          "Beef, chicken, or vegetarian rolled in thin pita bread, lettuce, tomatoes & hummus.",
      },
      {
        name: "Grilled Leg of Lamb",
        description: "Roasted in Mediterranean seasoning & herbs.",
      },
      {
        name: "Saffron Yellow Rice Tray",
        description: "Grilled Vegetables Tray",
      },
      {
        name: "Mozzarella & Tomato Display",
        description: "",
      },
      {
        name: "Mezza",
        description:
          "A Mediterranean sampler of hummus, grape leaves, lebneh, kalamata olives & fresh tomatoes topped with mozzarella Feeds 20 people.",
      },
      {
        name: "Babaghanough",
        description:
          "Roasted eggplant smoothly blended with sesame sauce & olive oil.",
      },
      {
        name: "Tabullah",
        description:
          "Finely chopped tomatoes, parsley, onions mixed with cracked wheat, olive oil & lemon.",
      },
      {
        name: "Hummus",
        description:
          "Tray of our house specialty of smoothly blended chick peas",
      },
      {
        name: "Mediterranean Bites 50/pcs",
        description:
          "Crispy pita triangles topped with pâté mixture of feta, sun-dried tomatoes & herbs.",
      },
      {
        name: "Vegetarian Assortment 75/pcs",
        description:
          "Asparagus spears wrapped with filo crust. Spinach stuffed with filo & feta. Portabella puffs stuffed with sun-dried tomatoes feta mixture.",
      },
      {
        name: "Grape Leaves 20/pcs",
        description: "",
      },
    ],
  }),
};
</script>
